
import { defineComponent } from 'vue'

import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from '@/util/toast'
import { useErrorHandler } from '@/util/error-handler'

import FormField from '@/components/FormField.vue'

export default defineComponent({
  components: { FormField },
  setup() {
    const toast = useToast()
    const { handleError } = useErrorHandler(toast)
    return {
      v$: useVuelidate(),
      handleError,
      ...toast,
    }
  },
  data() {
    return {
      submitted: false,
      loading: false,
      form: {
        name: '',
        password: '',
      },
    }
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        password: {
          required,
        },
      },
    }
  },
  methods: {
    async handleSignin() {
      this.submitted = true
      if (this.v$.$invalid) return

      try {
        this.loading = true
        await this.$store.dispatch('sigin', this.form)
        this.$router.push({ path: '/inventories' })
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
  },
})
